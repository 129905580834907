import React, {useState} from 'react'
import Collapsible from 'react-collapsible'
import {LineEdit} from './EditControls.js'
import {strings} from './Localization.js'

export function normalizeUsername(username) {
  return username.toLowerCase().trim()
}

export function Kostenstellen(props) {
  const usernames = [
    ...props.buchungen.map(buchung => normalizeUsername(buchung.username)),
    ...props.kosten.map(buchung => normalizeUsername(buchung.username))]
  const uniqueUsernames = [...new Set(usernames)].filter(username => 
    !props.kostenstellen.map(k => normalizeUsername(k.username)).includes(normalizeUsername(username)))
  return (<div>
    { uniqueUsernames.length > 0 && renderTitle() }
    {uniqueUsernames.map(username =>
      <KostenstelleErstellen
        key={username}
        username={username}
        onSave={kostenstelle => props.neueKostenstelleClicked(username, kostenstelle)}/>)}
    <Collapsible
      lazyRender="true"
      trigger={strings.kostenstellenKostenstellenBearbeiten}>
      { uniqueUsernames.length === 0 && renderTitle() }
      {props.kostenstellen.map(kostenstelle => 
        <LineEdit
          key={kostenstelle.id}
          value={kostenstelle.kostenstelle}
          title={kostenstelle.username}
          onSave={(value) => {props.kostenstelleEdited(kostenstelle.id, value)}}/>)}
    </Collapsible>
    </div>)
}
      
function KostenstelleErstellen(props) {
  const [kostenstelle, setKostenstelle] = useState(props.username)
  const onSave = (e) => {
    e.preventDefault()
    props.onSave(kostenstelle)
  }
  return (
    <div className="horizontal-layout">
      <span className="edit-line-key">{props.username}</span>
      <form 
        className="edit-line-form"
        onSubmit={onSave}>
        <div className="edit-line-value">
          <input
            className="carsharing-input"
            required
            autoFocus
            type={props.type}
            step={props.steps}
            pattern={props.pattern}
            value={kostenstelle}
            placeholder={strings.kostenstellenKostenstellePlaceholder}
            title={strings.kostenstellenKostenstellePlaceholder}
            onChange={(e) => setKostenstelle(e.target.value) }/>
        </div>
        <div className="edit-line-controls">
          <button
            className={"highlighted"}
            type="submit">
            {strings.allgemeinSpeichern}
          </button>
        </div>
      </form>
    </div>)
}

function renderTitle() {
  return (
    <>
      <div className="kostenstellen-title horizontal-layout">
        <span className="edit-line-key">{strings.kostenstellenBenutzername}</span>
        <div className="edit-line-value">{strings.kostenstellenKostenstelle}</div>
      </div>
    </>)
}
