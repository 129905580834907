export function date(dateTime) {
  return (dateTime
    ? dateTime.toLocaleDateString("de-CH",
        {weekday: "short", day: "2-digit", month: "short", hour:"2-digit", minute:"2-digit"})
    : "-")
}

export function endDate(dateTime) {
  if (dateTime && dateTime.getHours() === 0 && dateTime.getMinutes() === 0) {
    let result = new Date(dateTime.getTime())
    result.setDate(dateTime.getDate() - 1)
    return result.toLocaleDateString("de-CH",
        {weekday: "short", day: "2-digit", month: "short"}) + ", 24:00"
  }
  return date(dateTime)
}

export function duration(begin, end) {
  if (begin == null || end == null)
    return "-"
      const ms = end - begin
      const hours = Math.floor(ms / 3600 / 1000) % 24
      const days = Math.floor(ms / 24 / 3600 / 1000)
      const formattedDays = (days === 0 ? ""
          : days === 1 ? " 1 Tag "
          : days + " Tage ")
      const formattedHours = (hours > 0 ? hours + " h" : "")
      return formattedDays + formattedHours
}
