import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useReactToPrint} from 'react-to-print';
import Collapsible from 'react-collapsible'
import {usePapaParse} from 'react-papaparse'
import MarkdownView from 'react-showdown'
import {isAdmin} from './Authorization.js'
import {einträgeLesen, Buchhaltung} from './Buchhaltung.js'
import {DateEdit, LineEdit} from './EditControls.js'
import {Kostenstellen} from './Kostenstellen.js'
import {strings} from './Localization.js'
import './Abrechnung.css'

export function Abrechnungen(props) {
  return (
    <div className="abrechnung">
      { isAdmin() && <>
        <h2>Kostenstellen</h2>
        <Kostenstellen
          buchungen={props.buchungen}
          kosten={props.kosten}
          kostenstellen={props.kostenstellen}
          neueKostenstelleClicked={props.neueKostenstelleClicked}
          kostenstelleEdited={props.kostenstelleEdited}/>
      </>}
      <h2>Abrechnungen</h2>
      { isAdmin() && <>
        <AbrechnungErstellen
          neueAbrechnungClicked={props.neueAbrechnungClicked}/>
        <Collapsible
          className="gelöschte-abrechnungen"
          openedClassName="gelöschte-abrechnungen"
          trigger={strings.abrechnungGelöschteAbrechnungen}>
          { props.abrechnungen.filter(abrechnung => abrechnung.gelöscht !== null)
            .map(abrechnung => renderGelöschteAbrechnung(props, abrechnung)) }
        </Collapsible>
      </>}
      { props.abrechnungen
        .filter(abrechnung => !abrechnung.gelöscht)
        .sort((a, b) => a.id > b.id ? -1 : 1)
        .map((abrechnung, index) => 
          <Abrechnung
            key={abrechnung.id}
            dateTime={props.dateTime}
            abrechnung={abrechnung}
            buchhaltung={props.buchhaltung}
            fahrzeuge={props.fahrzeuge}
            buchungen={props.buchungen}
            kosten={props.kosten}
            kostenstellen={props.kostenstellen}
            aktuelleAbrechnung={index===0}
            neueAbrechnungClicked={props.neueAbrechnungClicked}
            abrechnungLöschenClicked={props.abrechnungLöschenClicked}
            abrechnungErstellenClicked={props.abrechnungErstellenClicked}
            buchhaltungEdited={props.buchhaltungEdited}
            abrechnungEdited={props.abrechnungEdited}/>) }
    </div>)
}

function Abrechnung(props) {
  const {readString} = usePapaParse();
  const [preview, setPreview] = useState("")
  const letzteEinträge = useMemo(() =>
    Object.fromEntries(props.fahrzeuge.map(fahrzeug =>
      [fahrzeug.id, letzterEintrag(readString, props.buchhaltung, fahrzeug.id)]))
  , [props.fahrzeuge, props.buchhaltung, readString])
  const setAbrechnungPreview = useCallback((abrechnung) => {
    setPreview({
      content: abrechnung,
      title: `${strings.abrechnungFahrkostenabrechnung} ${props.abrechnung.name}`})
  }, [props.abrechnung.name])
  const setBilanzPreview = useCallback((bilanz) => {
    setPreview({
      content: bilanz,
      title: `${strings.abrechnungBilanz} ${props.abrechnung.name}`})
  }, [props.abrechnung.name])
  return (
    <>
      { preview?.content &&
        <AbrechnungPreview
          content={preview?.content}
          title={preview?.title}
          onCancel={() => setPreview(null)}/> }
      { !isAdmin() && <>
        <div className="horizontal-layout">
          <div className="name">{props.abrechnung.name}</div>
          <button
            className={props.abrechnung.abrechnung ? "highlighted" : "hidden"}
            type="button"
            onClick={() => setPreview(props.abrechnung.abrechnung)}>
            {strings.abrechnungAbrechnungAnzeigen}
          </button>
        </div>
      </>}
      { isAdmin() && <>
        <h2>{props.abrechnung.name}</h2>
        <div key={props.abrechnung.id}>
          <div className="horizontal-layout">
            <button
              className={props.abrechnung.abrechnung ? "highlighted" : "hidden"}
              type="button"
              onClick={() => setAbrechnungPreview(props.abrechnung.abrechnung)}>
              {strings.abrechnungAbrechnungAnzeigen}
            </button>
            <button
              className={props.abrechnung.bilanz ? "highlighted" : "hidden"}
              type="button"
              onClick={() => setBilanzPreview(props.abrechnung.bilanz)}>
              {strings.abrechnungBilanzAnzeigen}
            </button>
          </div>
          <Collapsible
            open={!props.abrechnung.abrechnung}
            className="abrechnung-bearbeiten"
            openedClassName="abrechnung-bearbeiten"
            trigger={strings.abrechnungAbrechnungBearbeiten}>
            { renderAbrechnungBearbeiten(props, letzteEinträge, setAbrechnungPreview) }
          </Collapsible>
        </div>
      </> }
    </>)
}
  
function AbrechnungPreview(props) {
  const previewRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => previewRef.current,
    documentTitle: props.title,
    pageStyle: "@page { margin: 2.5cm; } .content { font-size: 10px; }"
  })
  return (
    <div
      className="overlay">
      <div className="header horizontal-layout">
        <button
          className="highlighted"
          type="button"
          onClick={handlePrint}>
          {strings.allgemeinDrucken}
        </button>
        <button
          type="button"
          onClick={props.onCancel}>
          {strings.allgemeinZurück}
        </button>
      </div>
      <div
        className="content"
        ref={previewRef}>
        <MarkdownView
          markdown={props.content}
          options={{tables: true}}/>
      </div>
    </div>)
}

function renderAbrechnungBearbeiten(props, letzteEinträge, setAbrechnungPreview) {
  const abrechnungErstellenClicked = async () => {
    const result = await props.abrechnungErstellenClicked(props.abrechnung.id)
    if (result.abrechnung) {
      setAbrechnungPreview(result.abrechnung)
    }
  }
  return (
    <>
      <LineEdit
        title={strings.abrechnungAbrechnungsname}
        value={props.abrechnung.name}
        onSave={(value) => {props.abrechnungEdited(props.abrechnung.id, {"name": value})}}/>
      <DateEdit
        title={strings.abrechnungDatum}
        value={props.abrechnung.datum}
        onSave={(value) => {props.abrechnungEdited(props.abrechnung.id, {"datum": value})}}/>
      { props.buchhaltung
        .filter(buchhaltung => buchhaltung.abrechnungsId === props.abrechnung.id)
        .sort((a, b) => a.fahrzeugId < b.fahrzeugId ? -1 : 1)
        .map(buchhaltung =>
          <Buchhaltung
            key={buchhaltung.id}
            dateTime={props.dateTime}
            buchhaltung={buchhaltung}
            buchhaltungEdited={props.buchhaltungEdited}
            fahrzeuge={props.fahrzeuge}
            buchungen={props.buchungen}
            kosten={props.kosten}
            kostenstellen={props.kostenstellen}
            aktuelleAbrechnung={props.aktuelleAbrechnung}
            letzterEintrag={letzteEinträge[buchhaltung.fahrzeugId]}/>)}
      <button
        className="highlighted"
        type="button"
        onClick={abrechnungErstellenClicked}>
        {strings.abrechnungAbrechnungErstellen}
      </button>
      <button
        type="button"
        onClick={() => props.abrechnungLöschenClicked(props.abrechnung.id)}>
        {strings.abrechnungAbrechnungLöschen}
      </button>
    </>)
}

function AbrechnungErstellen(props) {
  const [name, setName] = useState("")
  const onNeueAbrechnungClicked = (e) => {
    e.preventDefault()
    props.neueAbrechnungClicked(name)
    setName("")
  }
  return (
    <form
      className="neue-abrechnung"
      onSubmit={onNeueAbrechnungClicked}>
      <input
        className="carsharing-input"
        required
        type="text"
        placeholder={strings.abrechnungAbrechnungsname}
        value={name}
        onChange={(e) => { setName(e.target.value)}}/>
      <button
        className="highlighted"
          type="submit">
        {strings.abrechnungNeueAbrechnung}
      </button>
    </form>)
}

function renderGelöschteAbrechnung(props, abrechnung) {
  return (
      <div key={abrechnung.id}>
        <span>{abrechnung.name}</span>
        <button
          type="button"
          onClick={() => props.abrechnungEdited(abrechnung.id, {"gelöscht": null})}>
          {strings.allgemeinWiederherstellen}
        </button>
      </div>)
}

function letzterEintrag(reader, fahrtenbücher, fahrzeugId) {
  const historie = fahrtenbücher
    .filter(f => f.fahrzeugId === fahrzeugId)
    .sort((a, b) => a.id > b.id ? -1 : 1)
  for (const f of historie) {
    const letzterEintrag = einträgeLesen(reader, f.einträge)
      ?.findLast(eintrag => eintrag.Datum)?.Datum
    if (letzterEintrag)
      return letzterEintrag
  }
  return ""
}
