export function renderChevronUp(onClick) {
  return (
    <svg className="arrow-button" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
      onClick={onClick}>
      <path stroke="currentColor" strokeWidth="3" fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
    </svg>)
}

export function renderChevronDown(onClick) {
  return (
    <svg className="arrow-button" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
      onClick={onClick}>
      <path stroke="currentColor" strokeWidth="3" fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
    </svg>)
}

export function renderChevronLeft(onClick) {
  return (
    <svg className="arrow-button" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
      onClick={onClick}>
      <path stroke="currentColor" strokeWidth="3" fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
    </svg>)
}

export function renderChevronRight(onClick) {
  return (
    <svg className="arrow-button" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
      onClick={onClick}>
      <path stroke="currentColor" strokeWidth="3" fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg>)
}

