import {useEffect} from 'react'

export function useSocket(socket, channel, callback) {
  useEffect(() => {
    socket.on(channel, callback)

    return () => {
      socket.off(channel)
    }
  }, [callback, channel, socket])
}

