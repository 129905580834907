import React, {useState} from 'react'
import {usePapaParse} from 'react-papaparse'
import {integer, twoDigits} from './EditControls.js'
import {normalizeUsername} from './Kostenstellen.js'
import {strings} from './Localization.js'
import './Buchhaltung.css'

let keyIndex = 0;

export function Buchhaltung(props) {
  const [showEdit, setShowEdit] = useState(false)
  const fahrzeugname = props.fahrzeuge.find(fahrzeug => fahrzeug.id === props.buchhaltung.fahrzeugId)?.name
  return (
    <div className="horizontal-layout">
      {showEdit &&
        <FahrtenbuchEditor
          dateTime={props.dateTime}
          buchhaltung={props.buchhaltung}
          buchungen={props.buchungen}
          kosten={props.kosten}
          kostenstellen={props.kostenstellen}
          buchhaltungSaved={props.buchhaltungEdited}
          speichernClicked={() => setShowEdit(false)}
          abbrechenClicked={() => setShowEdit(false)}
          aktuelleAbrechnung={props.aktuelleAbrechnung}
          letzterEintrag={props.letzterEintrag}/>}
      <span className="edit-line-key">{fahrzeugname}</span>
      <button
        className={props.buchhaltung.einträge?.length > 0 ? "" : "highlighted"}
        type="button"
        onClick={() => setShowEdit(true)}>
        {props.buchhaltung.einträge?.length > 0 ? strings.buchhaltungBearbeiten : strings.buchhaltungErstellen }
      </button>
    </div>)
}

function FahrtenbuchEditor(props) {
  const {jsonToCSV, readString} = usePapaParse();
  const [einträge, setEinträge] = useState(einträgeLesen(readString, props.buchhaltung.einträge))
  const [showVorausfüllen, setShowVorausfüllen] = useState(props.aktuelleAbrechnung)
  const onSubmit = (e) => { 
    e.preventDefault();
    props.buchhaltungSaved(props.buchhaltung.id, einträgeSchreiben(jsonToCSV, einträge));
    props.speichernClicked();
  }
  const updateEinträge = (f) => {
    let copy = [...einträge]
    f(copy)
    setEinträge(copy)
  }
  const onVorausfüllen = () => {
    updateEinträge(e => e.push(...einträgeVorausfüllen(props)))
    setShowVorausfüllen(false)
  }
  return (
    <form
      className="buchhaltung editor"
      onSubmit={onSubmit}
      onKeyDown={(e) => {if (e.key === "Enter") { e.preventDefault(); };}}>
      <div className="header horizontal-layout">
        <button
          className="highlighted"
          type="submit">
          {strings.allgemeinSpeichern}
        </button>
        <button
          type="button"
          onClick={props.abbrechenClicked}>
          {strings.allgemeinAbbrechen}
        </button>
        { showVorausfüllen && <button
            className="vorausfüllen"
            type="button"
            onClick={onVorausfüllen}>
            {strings.buchhaltungVorausfüllen}
          </button> }
      </div>
      <div className="einträge">
        {einträge.map((eintrag, id) =>
          <FahrtenbuchLineEdit
            key={eintrag.Key}
            data={eintrag}
            onChange={(key, value) => updateEinträge(e => e[id][key] = value) }
            onBlur={(key, value) => updateEinträge(e => e[id][key] = addThousandsFromHistory(value, e.slice(0, id)))}
            rowAdded={(type) => updateEinträge(e => e.splice(id, 0, {"Type": type, "Key": keyIndex++})) }
            rowDeleted={() => updateEinträge(e => e.splice(id, 1)) }/>)}
        <NewLineControl
          rowAdded={(type) => updateEinträge(e => e.push({"Type": type, "Key": keyIndex++})) }/>
      </div>
    </form>)
}

function FahrtenbuchLineEdit(props) {
  return (
    <>
      <NewLineControl
        rowAdded={props.rowAdded}/>
      <div className="no-wrap">
      <div className="horizontal-layout">
        { props.data?.Type === 'Fahrt' &&
          <FahrtEditor
            data={props.data}
            onChange={props.onChange}
            onBlur={props.onBlur}/>}
        { props.data?.Type === 'Ausgabe' &&
          <AusgabeEditor
            data={props.data}
            onChange={props.onChange}/>}
        { props.data?.Type === 'Zuweisung' &&
          <ZuweisungEditor
            data={props.data}
            onChange={props.onChange}/>}
      </div>
        { props.data.Type &&
          <button
            className="delete-button"
            type="button"
            title={strings.buchhaltungZeileEntfernen}
            onClick={props.rowDeleted}>
            &times;
          </button> }
      </div>
    </>)
}

function NewLineControl(props) {
  const typeLocalizations = {
    'Fahrt': strings.buchhaltungTypFahrt,
    'Ausgabe': strings.buchhaltungTypAusgabe,
    'Zuweisung': strings.buchhaltungTypZuweisung}
  const [showDropdown, setShowDropdown] = useState(false)
  return (
    <>
      { showDropdown && <>
        <div
          className="selection-overlay"
          onClick={() => setShowDropdown(false)} />
        <div className="selection-box">
          { Object.keys(typeLocalizations).map(type =>
              <div
                key={type}
                className="item"
                onClick={() => { props.rowAdded(type); setShowDropdown(false); }}>
                <span>{typeLocalizations[type]}</span>
              </div>) }
        </div>
      </> }
      <div className="add-button-wrapper">
        <button
          className="add-button"
          type="button"
          title={strings.buchhaltungZeileHinzufügen}
          onClick={() => setShowDropdown(true)}>
        +
        </button>
      </div>
    </>)
}

function FahrtEditor(props) {
  return (
    <div className="horizontal-layout">
      <div className="no-wrap">
        <span>{strings.buchhaltungDatum}</span>
        <input
          type="date"
          required
          value={props.data.Datum}
          onChange={e => props.onChange("Datum", e.target.value)}/>
      </div>
      <div className="no-wrap">
        <span>{strings.buchhaltungKostenstelle}</span>
        <input
          type="text"
          required
          value={props.data.Fahrer}
          onChange={e => props.onChange("Fahrer", e.target.value)}/>
      </div>
      <div className="no-wrap">
        <span>{strings.buchhaltungStartkilometer}</span>
        <input
          className="kilometerstand"
          type="number"
          step="1"
          pattern={integer}
          value={props.data.Startkilometer}
          onChange={e => props.onChange("Startkilometer", e.target.value)}
          onBlur={e => props.onBlur("Startkilometer", e.target.value)}
          onWheel={e => e.target.blur()}/>
      </div>
      <div className="no-wrap">
        <span>{strings.buchhaltungEndkilometer}</span>
        <input
          className="kilometerstand"
          type="number"
          step="1"
          pattern={integer}
          required
          value={props.data.Endkilometer}
          onChange={e => props.onChange("Endkilometer", e.target.value)}
          onBlur={e => props.onBlur("Endkilometer",
            addThousandsFromInitialValue(e.target.value, props.data.Startkilometer))}
          onWheel={e => e.target.blur()}/>
      </div>
      <div className="no-wrap">
        <span>{strings.buchhaltungKommentar}</span>
        <input
          className="kommentar"
          type="text"
          value={props.data.Kommentar}
          onChange={e => props.onChange("Kommentar", e.target.value)}/>
      </div>
      <div className="no-wrap">
        <span>{strings.buchhaltungRabatt}</span>
        <input
          type="checkbox"
          checked={props.data.Rabatt === "1"}
          onChange={e => props.onChange("Rabatt", e.target.checked ? "1" : "0")}/>
      </div>
    </div>)
}

function AusgabeEditor(props) {
  return (
    <div className="horizontal-layout">
      <div className="no-wrap">
        <span>{strings.buchhaltungDatum}</span>
        <input
          type="date"
          required
          value={props.data.Datum}
          onChange={e => props.onChange("Datum", e.target.value)}/>
      </div>
      <div className="no-wrap">
        <span>{strings.buchhaltungKostenstelle}</span>
        <input
          type="text"
          required
          value={props.data.Fahrer}
          onChange={e => props.onChange("Fahrer", e.target.value)}/>
      </div>
      <div className="no-wrap">
        <span>{strings.buchhaltungAufwendung}</span>
        <input
          type="text"
          required
          value={props.data.Ausgabe}
          onChange={e => props.onChange("Ausgabe", e.target.value)}/>
      </div>
      <div className="no-wrap">
        <span>{strings.buchhaltungBetrag}</span>
        <input
          type="number"
          step="0.01"
          pattern={twoDigits}
          required
          value={props.data.Ausgabebetrag}
          onChange={e => props.onChange("Ausgabebetrag", e.target.value)}
          onWheel={e => e.target.blur()}/>
      </div>
      <div className="no-wrap">
        <span>{strings.buchhaltungKommentar}</span>
        <input
          className="kommentar"
          type="text"
          value={props.data.Kommentar}
          onChange={e => props.onChange("Kommentar", e.target.value)}/>
      </div>
    </div>)
}

function ZuweisungEditor(props) {
  return (
    <div className="horizontal-layout">
      <div className="no-wrap">
        <span>{strings.buchhaltungZuweisung}</span>
        <input
          type="text"
          required
          value={props.data.Zuweisung}
          onChange={e => props.onChange("Zuweisung", e.target.value)}/>
      </div>
      <div className="no-wrap">
        <span>{strings.buchhaltungZuweisungswert}</span>
        <input
          type="text"
          required
          value={props.data.Zuweisungswert}
          onChange={e => props.onChange("Zuweisungswert", e.target.value)}/>
      </div>
      { props.data.Zuweisung === "Serienzahlung" && <>
        <div className="no-wrap">
          <span>{strings.buchhaltungKostenstelle}</span>
          <input
            type="text"
            value={props.data.Fahrer}
            onChange={e => props.onChange("Fahrer", e.target.value)}/>
        </div>
        <div className="no-wrap">
          <span>{strings.buchhaltungAufwendung}</span>
          <input
            type="text"
            required
            value={props.data.Ausgabe}
            onChange={e => props.onChange("Ausgabe", e.target.value)}/>
        </div>
        <div className="no-wrap">
          <span>{strings.buchhaltungBetrag}</span>
          <input
            type="number"
            step="0.01"
            pattern={twoDigits}
            required
            value={props.data.Ausgabebetrag}
            onChange={e => props.onChange("Ausgabebetrag", e.target.value)}
            onWheel={e => e.target.blur()}/>
        </div>
      </>}
    </div>)
}

export function einträgeLesen(reader, csv) {
  if (!csv) {
    return []
  }
  let einträge = reader(csv, {header:true}).data
  return einträge
    .map(eintrag => {
      eintrag.Type = (
        eintrag.Zuweisung ? "Zuweisung"
        : eintrag.Ausgabe ? "Ausgabe"
        : eintrag.Endkilometer ? "Fahrt"
        : null)
      eintrag["Key"] = keyIndex++
      return eintrag;
    })
    .filter(eintrag => eintrag.Type)
}

function einträgeSchreiben(writer, json) {
  json = json.filter(eintrag => eintrag.Type) 
  return writer(json, {
    header: true,
    columns: 'Datum,Fahrer,Startkilometer,Endkilometer,Ausgabe,Ausgabebetrag,Zuweisung,Zuweisungswert,Kommentar,Rabatt'.split(',')})
}

function einträgeVorausfüllen(props) {
  const fahrten = props.buchungen
    .filter(buchung => 
      buchung.fahrzeugId === props.buchhaltung.fahrzeugId
      && !storniert(buchung)
      && beendet(buchung, props.dateTime)
      && buchung.ende > props.letzterEintrag)
    .map(buchung => Object({
      "Type": "Fahrt",
      "Datum": buchung.ende.split('T')[0],
      "Datetime": buchung.ende, // for sorting only
      "Fahrer": findKostenstelle(buchung.username, props.kostenstellen),
      "Key": keyIndex++}))
  const ausgaben = props.kosten
    .filter(kosten => 
      kosten.fahrzeugId === props.buchhaltung.fahrzeugId
      && !kosten.gelöscht
      && kosten.datum > props.letzterEintrag)
    .map(kosten => Object({
      "Type": "Ausgabe",
      "Datum": kosten.datum.split('T')[0],
      "Datetime": kosten.datum, // for sorting only
      "Fahrer": findKostenstelle(kosten.username, props.kostenstellen),
      "Ausgabe": kosten.name,
      "Ausgabebetrag": kosten.preis,
      "Key": keyIndex++}))
  const einträge = [...fahrten, ...ausgaben]
  return einträge.sort((a, b) => a.Datetime < b.Datetime ? -1 : 1)
}

function findKostenstelle(username, kostenstellen) {
  const kostenstelle = kostenstellen.find(k => normalizeUsername(k.username) === normalizeUsername(username))?.kostenstelle
  return kostenstelle ? kostenstelle : normalizeUsername(username)
}

function addThousandsFromHistory(value, einträge) {
  const found = parseInt(einträge.findLast(e => e.Endkilometer && e.Endkilometer >= 1000)?.Endkilometer)
  return addThousandsFromInitialValue(value, found)
}

function addThousandsFromInitialValue(value, initialValue) {
  if (!value || parseInt(value) >= 1000) {
    return value
  }
  return initialValue
    ? (initialValue - initialValue % 1000) + parseInt(value)
    : value
}

function storniert(buchung) {
  return buchung.beendet && buchung.beendet < buchung.start;
}

function beendet(buchung, dateTime) {
  return (buchung.beendet
    ? new Date(buchung.beendet) <= dateTime
    : new Date(buchung.ende) <= dateTime)
}
