import React, {useCallback, useState} from 'react'
import {strings} from './Localization.js'
import './Login.css'

export function Login(props) {
  const [name, setName] = useState()
  const [zugangscode, setZugangscode] = useState()
  const [wrongName, setWrongName] = useState()
  const [wrongZugangscode, setWrongZugangscode] = useState()

  const submitted = useCallback(async e => {
    try {
      e.preventDefault()
      const token = await props.onSubmit({"name": name, "zugangscode": zugangscode})
      setWrongName(token.wrongName)
      setWrongZugangscode(token.wrongZugangscode)
    } catch (e) {
      console.log(e)
      props.onError(strings.fehlerLogin)
    }
  }, [props, name, zugangscode])

  const nameChanged = (e) => {
    setName(e.target.value)
    setWrongName(false)
  }

  const zugangscodeChanged = (e) => {
    setZugangscode(e.target.value)
    setWrongZugangscode(false)
  }

  return (
      <div className="login">
        <img className="header-logo" src="HerzfeldSennrüti.svg" alt="Herzfeld Sennrüti"/>
        <h1>{strings.loginCarsharing}</h1>
        <form onSubmit={submitted}>
          <input
            className={wrongName ? "carsharing-input wrong-input" : "carsharing-input"}
            type="text"
            placeholder={strings.loginName}
            onFocus={() => setWrongName(false)}
            onChange={nameChanged} />
          <input
            className={wrongZugangscode ? "carsharing-input wrong-input" : "carsharing-input"}
            type="password"
            placeholder={strings.loginZugangscode}
            onFocus={() => setWrongZugangscode(false)}
            onChange={zugangscodeChanged} />
          <div>
            <button
              className="highlighted"
              type="submit">
              {strings.loginAnmelden}
            </button>
          </div>
        </form>
      </div>
  )
}
