import React, {useCallback, useState} from 'react'
import {QueryClient, QueryClientProvider } from 'react-query'
import {BackendModel, loginToBackend} from './BackendModel.js'
import {Carsharing} from './Carsharing.js'
import {API_URL} from './Environment.js'
import {Login} from './Login.js'
import {useStorage} from './UseStorage.js'
import './App.css'

const queryClient = new QueryClient()

export default function App(props) {
  const [session, setSession] = useStorage("session")
  const [errorMessage, setErrorMessage] = useState(null)

  const loginSubmitted = useCallback(async args => {
    const result = await loginToBackend(API_URL, args.name, args.zugangscode)
    setSession(result)
    return result
  }, [setSession])

  return (
      <QueryClientProvider client={queryClient}>
        {errorMessage && <div className="message-box">
          <div className="error-message">{errorMessage}</div>
          <button onClick={() => setErrorMessage(null)}>Ok</button></div>}
        {(!session?.token)
        ? <Login
            onSubmit={loginSubmitted}
            onError={setErrorMessage}/>
        : <Carsharing
            queryClient={queryClient}
            username={session.name}
            backend={new BackendModel(API_URL, session.token)}
            onLogout={() => setSession(null)}
            onError={setErrorMessage}/>}
      </QueryClientProvider>)
}
