import React from 'react';
import './TimeRangePicker.css';

export default class TimeRangePicker extends React.Component {
  //static uhrzeiten = [0, 8, 10, 12, 14, 16, 18, 20, 24]
  static uhrzeiten = [0, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 24]

  constructor(props) {
    super(props);
    this.state = {
      selectionBegin: null,
      selectionEnd: null,
      hoverBegin: null,
      hoverEnd: null
    };
  }

  dateRange() {
    if (this.props.startDate == null || this.props.endDate == null) {
      return [this.props.currentDateTime]
    }
    let result = []
    for (let date = this.props.startDate;
        date <= this.props.endDate;
        date = TimeRangePicker.datePlusDays(date, 1)) {
      result.push(date)
    }
    return result
  }

  render() {
    return (
        <div className="time-range-picker">
          { this.renderHeader() }
          { this.dateRange().map((date) => this.renderRow(date)) }
        </div>)
  }

  renderHeader() {
    return (
        <div className="board-row">
          <div className="datum"/>
          { TimeRangePicker.uhrzeiten.map((uhrzeit) =>
              <span className="uhrzeit" key={uhrzeit}>{("00" + uhrzeit).slice(-2) + ".00"}</span>) }
        </div>)
  }

  renderRow(date) {
    const squares = [];
    for (let idx=0; idx + 1 < TimeRangePicker.uhrzeiten.length; idx++) {
      squares.push(this.renderSquare(
            TimeRangePicker.dateAndHours(date, TimeRangePicker.uhrzeiten[idx]),
            TimeRangePicker.dateAndHours(date, TimeRangePicker.uhrzeiten[idx+1])))
    }
    return (
        <div className="board-row" key={date.toString()}>
          <div className="datum">
            {new Date(date).toLocaleDateString("de-CH",
                {weekday: "short", day: "2-digit", month: "short"})}
          </div>
          <div className="squares">
            { squares }
          </div>
        </div>
        );
  }

  renderSquare(begin, end) {
    const disabled = end < this.props.currentDateTime ? "disabled " : "";
    const selected =
      (this.state.selectionBegin != null && this.state.selectionEnd != null 
       && begin >= this.state.selectionBegin
       && end <= this.state.selectionEnd)
      ? "selected "
      : "";
    const selectStart = this.state.selectionBegin == null
      && begin >= this.state.hoverBegin && end <= this.state.hoverEnd
      ? "select-start "
      : "";
    const selectEnd = this.state.selectionBegin != null && this.state.selectionEnd == null
      && begin >= this.state.selectionBegin && end <= this.state.hoverEnd
      ? "select-end "
      : "";
    const reserved = this.props.isReserved({start: begin, end: end})
      ? "reserved "
      : "";
    return (
        <button
          className={"square " + disabled + selected + selectStart + selectEnd + reserved}
          key={begin.toString()}
          onClick={() => {
            if (end < this.props.currentDateTime) {
              return;
            }
            if (this.state.selectionBegin != null
                && this.state.selectionEnd == null
                && this.state.selectionBegin < end) {
              this.setState({selectionEnd: end})
            }
            else {
              this.setState({
                selectionBegin: begin,
                selectionEnd: null,
                hoverBegin: begin,
                hoverEnd: end
                })
              }
          }}
          onMouseEnter={() => {
            this.setState({hoverBegin: begin, hoverEnd: end})
          }}
          onMouseLeave={() => {
            this.setState({hoverBegin: null, hoverEnd: null})
          }}
        />
      )
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.onChange([this.state.selectionBegin, this.state.selectionEnd])
    }
  }
  
  static dateAndHours(date, hours) {
    let result = new Date(date.getTime())
    result.setHours(hours, 0, 0, 0)
    return result
  }
  
  static datePlusDays(date, days) {
    let result = new Date(date.getTime())
    result.setDate(date.getDate() + days)
    return result
  }
}
