import io from 'socket.io-client'
import {CollectionModel} from './CollectionModel.js'
import {HttpSession} from './HttpSession.js'

export async function loginToBackend(host, username, zugangscode) {
  return await new HttpSession(/*auth=*/null).post(`${host}/token`,
      {"name": username, "zugangscode": zugangscode})
}

export class BackendModel {
  constructor(host, token) {
    this.host = host
    this.session = new HttpSession(token)
    this.buchungen = new CollectionModel(this.session, `${this.host}/buchungen`)
    this.fahrzeuge = new CollectionModel(this.session, `${this.host}/fahrzeuge`)
    this.kosten = new CollectionModel(this.session, `${this.host}/kosten`)
    this.kostenstellen = new CollectionModel(this.session, `${this.host}/kostenstellen`)
    this.abrechnungen = new CollectionModel(this.session, `${this.host}/abrechnungen`)
    this.buchhaltung = new CollectionModel(this.session, `${this.host}/buchhaltung`)
    this.socket = new io(host, { path: "/ws/socket.io/", transports: ['websocket', 'polling']});
  }

  async getDateTime() {
    return await this.session.get(`${this.host}/date-time`)
  }

  async abrechnungAuswerten(abrechnungsId) {
    return await this.session.post(`${this.host}/abrechnungen/${abrechnungsId}/auswerten`)
  }
}
