import { useState } from 'react';

export function useStorage(itemName) {
  const loadToken = () => {
    const tokenString = localStorage.getItem(itemName)
    const userToken = JSON.parse(tokenString)
    return userToken
  }

  const [token, setToken] = useState(loadToken())

  const saveToken = userToken => {
    localStorage.setItem(itemName, JSON.stringify(userToken))
    setToken(userToken)
  }

  return [token, saveToken]
}

