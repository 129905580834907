import React, {useEffect, useState} from 'react'
import {strings} from './Localization.js'

export const twoDigits = /^\d*(\.\d{0,2})?$/g
export const integer = /^\d+$/g

export function formatPreis(number) {
  if (typeof(number) !== "number") {
    return "-"
  }
  return "CHF "  + (number !== Math.floor(number) ? number.toFixed(2) : number + ".-")
}

export function PriceEdit(props) {
  return (
    <PropertyEdit
      value={props.value}
      title={props.title}
      onSave={props.onSave}
      type="number"
      steps="0.01"
      hint="Zahlenwert mit 2 Nachkommastellen eingeben."
      format={formatPreis}
      pattern={twoDigits}/>)
}

export function LineEdit(props) {
  return (
    <PropertyEdit
      value={props.value}
      title={props.title}
      onSave={props.onSave}
      type="text"/>)
}

export function TextEdit(props) {
  return (
    <PropertyEdit
      value={props.value}
      title={props.title}
      format={props.format}
      onSave={props.onSave}
      type="textarea"/>)
}

export function DateEdit(props) {
  return (
    <PropertyEdit
      value={props.value}
      title={props.title}
      format={props.format}
      onSave={props.onSave}
      type="date"/>)
}

function PropertyEdit(props) {
  const [editActive, setEditActive] = useState(false)
  const [value, setValue] = useState(props.value)
  const [inputValid, setInputValid] = useState(true)
  const onEdit = () => {
    setValue(props.value)
    setEditActive(true)
  }
  const onSave = (e) => {
    e.preventDefault()
    props.onSave(value)
    setEditActive(false)
  }
  useEffect(() => {
    setInputValid(!props.pattern || (value && value.toString().match(props.pattern)))
  }, [value, props.pattern])
  return (
    <div className="horizontal-layout">
      <span className="edit-line-key">{props.title}</span>
      { editActive &&
        <form 
          className="edit-line-form"
          onSubmit={onSave}>
          <div className="edit-line-value">
            {props.type !== "textarea" && <input
              className="carsharing-input"
              autoFocus
              type={props.type}
              step={props.steps}
              pattern={props.pattern}
              value={value ? value : ""}
              title={props.hint}
              onChange={(e) => setValue(e.target.value) }/>}
            {props.type === "textarea" && <textarea
              autoFocus
              rows="8"
              value={value ? value : ""}
              onChange={(e) => setValue(e.target.value)}/>}
          </div>
          <div className="edit-line-controls">
            <button
              className={inputValid ? "highlighted" : "hidden"}
              type="submit">
              {strings.allgemeinSpeichern}
            </button>
            <button
              type="button"
              onClick={() => setEditActive(false)}>
              {strings.allgemeinAbbrechen}
            </button>
          </div>
        </form>}
      { !editActive &&
        <>
          <div className="edit-line-value">
            <span>{props.format ? props.format(props.value) : props.value}</span>
          </div>
          <div className="edit-line-controls">
            <button
              type="button"
              onClick={onEdit}>
              {strings.allgemeinEditieren}
            </button>
          </div>
        </>}
    </div>)
}

