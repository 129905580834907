export class HttpSession {
  constructor(authorizationToken) {
    this.addAuthorization = (headers) => {
      if (authorizationToken) {
        headers.Authorization = `Bearer ${authorizationToken}`
      }
      return headers
    }
  }

  async get(address) {
    const response = await fetch(address, {
      method: 'GET',
      headers: this.addAuthorization({})
    })
    if (response.status >= 400 && response.status < 600) {
      throw new Error(`GET ${address}: ${response.status}`)
    }
    return await response.json()
  }

  async post(address, payload) {
    const response = await fetch(address, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.addAuthorization({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    })
    if (response.status >= 400 && response.status < 600) {
      throw new Error(`POST ${address}: ${response.status}`)
    }
    return await response.json()
  }

  async put(address, payload) {
    const response = await fetch(address, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: this.addAuthorization({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    })
    if (response.status >= 400 && response.status < 600) {
      throw new Error(`PUT ${address}: ${response.status}`)
    }
    return await response.json()
  }

  async deleteItem(address) {
    const response = await fetch(address, {
      method: 'DELETE',
      headers: this.addAuthorization({})
    })
    if (response.status >= 400 && response.status < 600) {
      throw new Error(`DELETE ${address}: ${response.status}`)
    }
  }
}
