export class CollectionModel {
  constructor(httpSession, httpAddress) {
    this.address = httpAddress
    this.session = httpSession
  }

  async get() {
    return await this.session.get(this.address)
  }

  async create(buchung) {
    return await this.session.post(this.address, buchung)
  }

  async updateId(id, update) {
    return await this.session.put(`${this.address}/${id}`, update)
  }

  async deleteId(id) {
    return await this.session.deleteItem(`${this.address}/${id}`)
  }
}
